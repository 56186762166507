import React, {  useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import RightContent from "../components/RightContent/RightContent";
import moment from 'moment'
import { Outlet } from 'react-router-dom'
import customRoutes from '@/router/routes';
import styles from './BaseLayout.less'
import {Button, Layout, Menu} from 'antd';
import {refresh} from "@/services/third";
import {CopyrightOutlined} from "@ant-design/icons";
import axios from "axios";


const { Header, Content, Sider, Footer } = Layout;
let route = customRoutes.filter((item)=>{return !item.hide})

const BaseLayout =()=>{
    const navigate  = useNavigate()
    const location = useLocation()
    const [auth ,setAuth] = useState(false)
    const [defaultSelected,setDefaultSelected]= useState('0')

    const [team, setTeam]= useState('')
    const [web, setWeb]= useState('')
    const [startDate, setStartDate]= useState('')
    const [cpright, setCpright]= useState('')

    useEffect(()=>{
        let token = sessionStorage.getItem('TOKEN')

        if(token){
            setAuth(true)
        }else{
            navigate('/login')
        }
        if(location.pathname === '/'){
            navigate('/login')
        }
        let val = [route.findIndex((item)=>{return '/'+item.path === location.pathname}).toString()]
        setDefaultSelected(val)
    },[location.pathname])


    useEffect(() => {
        const timer = setInterval(() => {
            let token = window.sessionStorage.getItem('TOKEN');
            if (token) {
                refresh().then((res) => {
                    window.sessionStorage.setItem('TOKEN', res.token)
                }).catch((err) => {
                    console.log('refresh error: ', err);
                });
            }
        }, 1800 * 1000);
        return () => clearInterval(timer)
    }, []);


    useEffect(() => {
        axios.get(process.env.REACT_APP_COPYRIGHT_URL).then((res) => {
            let data = res.data;
            if (data.team) {
                setTeam(data.team);
            }
            if (data.domain) {
                setWeb(data.domain);
            }
            if (data.from) {
                setStartDate(data.from);
            }
            if (data.copyright) {
                setCpright(data.copyright);
            }
        }).catch((err) => {
            console.log('copyright error: ', err);
        });
    }, []);


    const render = <>
        <Layout className={styles.layout}>
            <Header className={styles.header}>
                <div className={styles.logo} >
                    <img src="/logo.png" alt=""/>
                </div>
                <span  className={styles.title}>毛毛虫掌柜</span>
                <RightContent />
            </Header>
            <Layout>
                <Sider className={styles.sider}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={defaultSelected}
                        selectedKeys={defaultSelected}
                        className={styles.menu}
                    >
                        {route && (
                            route.map((item,index) => {
                                return (
                                    <Menu.Item
                                        key={index.toString()}
                                        onClick={()=>{
                                                navigate(item.path)
                                            }
                                        }
                                        icon={item.icon}
                                    >
                                        <span>{item.name}</span>
                                    </Menu.Item>
                                )
                            }
                            ))}
                    </Menu>
                </Sider>
                <Layout >
                    <Content className={styles.content} >
                        <Outlet/>
                    </Content>
                    <Footer className={styles.footer}>
                        <div>
                            <Button type='link' onClick={() => {
                                window.open(`https://${web}`, '_blank');
                            }}>
                                {team}
                            </Button>
                            <span>{web}</span>
                            &nbsp;
                            <span>{startDate}</span>
                        </div>
                        {cpright && <div><CopyrightOutlined />&nbsp; {moment().year()} {cpright}</div>}
                    </Footer>
                </Layout>
            </Layout>
        </Layout>
    </>
    return (auth ? render : <></>)
};

export default BaseLayout;
