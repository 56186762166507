import { Menu, Dropdown } from 'antd';
import styles from './RightContent.less'
import React, {useEffect, useState} from 'react';
import { LogoutOutlined } from "@ant-design/icons";
import LogoutModal from "./components/LogoutModal";
import moment from "moment";
import {getThird} from "@/services/third";

const RightContent = () => {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [dateStr, setDateStr]= useState('');
  const [shopInfo, setShopInfo] = useState({});
  const user = window.sessionStorage.getItem('USER');

  const dayMap = {
    0: '星期日',
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
  };

  const onMenuClick = () => {
    setLogoutModalVisible(true)
  };

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined/>
        退出登录
      </Menu.Item>
    </Menu>
  );

  const changeLogoutModalVisible = (status) => {
    setLogoutModalVisible(status)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const now = moment();
      let currentDate = now.format('YYYY年MM月DD日');
      let currentTime = now.format('HH:mm');
      let currentDay = now.day();
      setDateStr(`${currentDate}  ${dayMap[currentDay]}  ${currentTime}`);
    }, 1000);
    return () => clearInterval(timer)
  }, [])

  useEffect(()=>{
    getThird('1').then((res) => {
      setShopInfo(res);
    })
  },[])

  return (
    <div className={styles['right']}>
      <div >
        <span className={styles['company']}>{shopInfo?.company}</span>
      </div>
      <div className={styles['right-box']}>
        <span className={styles['show-time']}>{dateStr ?? ''}&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Dropdown className={styles['action']} overlay={menuHeaderDropdown}>
          <span>你好，<a href="#">{user}</a></span>
        </Dropdown>
      </div>
      {logoutModalVisible ? <LogoutModal visible={logoutModalVisible} changeLogoutModalVisible={changeLogoutModalVisible} /> : null}
    </div>
  );
};
export default RightContent;
