import axios from 'axios'
import { message } from 'antd'

// axios 封装
// 请求时的拦截
axios.interceptors.request.use(
  config => {

    return config
  },
  error => {
    // 当请求异常时做一些处理
    return Promise.reject(error)
  }
)

// 响应时拦截
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // console.log('interceptors response error', error)
    return Promise.reject(error)
  }
)

const request = (url, options) => {

  let params = options.data

  let httpDefaultOpts = {
    method: options.method,
    baseURL: process.env.REACT_APP_BASE_URL,
    url: url,
    timeout: 3000,
    params: params,
    data: params,
  }

  if (options.method.toUpperCase() === 'GET') {
    if (httpDefaultOpts.params && !httpDefaultOpts.params.hasOwnProperty('force')) {
      httpDefaultOpts.params = {...httpDefaultOpts.params, force: 1};
    }
  }


  const token = window.sessionStorage.getItem('TOKEN')

  httpDefaultOpts.headers = {
    ...(options?.headers ?? {}),
  }

  if (token) {
    httpDefaultOpts.headers.token = token
  }

  if (!options.method || options.method.toUpperCase() === 'GET') {
    delete httpDefaultOpts.data
  } else {
    delete httpDefaultOpts.params
    delete httpDefaultOpts.paramsSerializer
  }
  if (options.responseType) {
    httpDefaultOpts.responseType = options.responseType
  }
  if (options.headers) {
    httpDefaultOpts.headers['Content-Type'] = options.headers['Content-Type']
  }

  return new Promise(function (resolve, reject) {
    axios(httpDefaultOpts)
      .then(response => {
        if (httpDefaultOpts.responseType === 'blob') {
          resolve(response.data)
        } else {
          if (response.data.errorCode === 0) {
            resolve(response.data.data)
          } else {
            message.error(response.data.msg);
            resolve(response.data)
          }
        }
      })
      .catch(response => {
        // 取消请求，超时，以及状态码 > 300 都会报错
        if (response.response.data?.msg) {
          message.error(response.response.data.msg);
        }
        reject(response)
        if (response.response.status === 401) {
          window.sessionStorage.setItem('TOKEN', '')
          if (window.location.hash.indexOf('?redirect=') === -1) {
            window.location.hash = '#/login?redirect=' + window.location.hash
          }
        }
      })
  })
}

export default request
