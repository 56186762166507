import { LockOutlined, UserOutlined, PictureOutlined, CopyrightOutlined } from '@ant-design/icons'
import { Alert, message, Form, Input, Row, Col, Space,Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { getCaptcha, login } from '@services/login'
import styles from './index.less'
import { useNavigate,useLocation } from "react-router-dom";
import routes from '@/router/routes'
import {getRandStr} from "@/utils/util";

const LoginMessage = ({ content }) => (
  <Alert
  style={{
    marginBottom: 24,
  }}
  message={content}
  type="error"
  showIcon
  />
)

const Login = () => {
    const [userLoginState, setUserLoginState] = useState({})
    const [captcha, setCaptcha] = useState('')
    const [captchaId, setCaptchaId] = useState('')
    const navigate = useNavigate()
    const location= useLocation()

  const fetchCaptcha = async () => {
    try {
      let params = {
        id: getRandStr(32),
      }
      const code = await getCaptcha(params)
      var reader = new FileReader()
      reader.readAsDataURL(code)
      reader.onload = function (e) {
        setCaptchaId(params.id)
        setCaptcha(this.result)
      }
    } catch {
      message.error('验证码获取失败，请重试！')
    }
  }
  useEffect(()=>{
    if (!captcha) {
      fetchCaptcha()
    }
  },[])

  const handleSubmit = async (values) => {
    try {
      // 登录
      const res = await login({ ...values, captcha_id: captchaId })
      if(res.token){
        window.sessionStorage.setItem('TOKEN', res.token)
        window.sessionStorage.setItem('USER', values.ac)

        const { hash } = location
        let redirect  = hash || ''
        redirect = redirect.replace('#', '')
        // 如果失败去设置用户错误信息
        if(redirect){
          navigate(redirect, {replace: true})
        }else if(routes.length && routes[1].path){
          navigate('/' + routes[1].path, {replace:true})
        }else{
          navigate('/Dashboard', {replace: true})
        }
        message.success('登录成功！')
      } else if (res.errorCode !== 0){
        fetchCaptcha()
        message.error('账号或密码错误')
      }
    } catch (error) {
      // message.error('登录失败，请重试！')
      fetchCaptcha()
    }
  }

  const { status, type: loginType } = userLoginState

  return (
    <div className={styles.container}>
      <div className={styles.lang}>
      </div>
      <div className={styles.content}>
        <Row>
          <Col offset={9} span={6}>
            <div className={styles.header}>
              <img src="/logo.png" alt=""/>
              <span >毛毛虫掌柜</span>
            </div>
          </Col>
        </Row>

          {status === 'error' && <LoginMessage content="账户或密码错误" />}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={async (values) => {
            await handleSubmit(values)
          }}
        >
          <Form.Item
            name="ac"
            validateFirst={true}
            wrapperCol={{ offset: 9, span: 6}}
            rules={[
                { required: true, message: '请输入登录账号!' },
                { type: 'string', min:6, max: 18, message: '登录账号在6-18个字符之间!' }
            ]}
          >
            <Input size="large" prefix={<UserOutlined className={styles.prefixIcon} />}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 9, span: 6 }}
            name="se"
            validateFirst={true}
            rules={[{ required: true, message: '请输入密码!' },
              { type: 'string', min:6, max: 18, message: '密码长度在6-18位之间!' },
              ({getFieldValue}) => ({
                validator(_, e) {
                  const value = getFieldValue('se');
                  if(value){
                    if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/).test(value)) {
                      return Promise.reject(new Error('密码必须包含字母和数字!'));
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),]}
          >
            <Input.Password  size="large" prefix={<LockOutlined className={styles.prefixIcon} />} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 9, span: 6 }}>
              <Row gutter={6}>
                <Col span={12}>
                  <Form.Item
                    name="captcha_code"
                    validateFirst={true}
                    rules={[{required: true, message: '请输入验证码！' }]}
                  >
                    <Input size="large" placeholder="请输入验证码" prefix={<PictureOutlined />} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <img
                    src={captcha}
                    onClick={() => {
                      fetchCaptcha()
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>

          <Form.Item wrapperCol={{ offset: 9, span: 6 }}>
            <Button size='large' type="primary" htmlType="submit"  className={styles['login-form-button']}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ margin: '48px 0 24px 0', textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)' }}>
        <Space>
          <CopyrightOutlined />
          <span>{new Date().getFullYear()}</span>
          <span>Peter Studio wcaqrl@qq.com</span>
        </Space>
      </div>
    </div>
  )
}

export default Login
