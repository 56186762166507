import React from "react";
import Page404 from "../pages/404";
import {HashRouter, useRoutes,} from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import Login from "../pages/User/Login";
import customRoutes from "./routes";
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';

const MyRouter = () => {
  return useRoutes([
    {path: "login", element: <Login/>},
    {
      path: "/",
      element: <BaseLayout/>,
      children: [...customRoutes],
    },
    {path: "*", element: <Page404/>},
  ]);
};
const RouterConfig = () => {
  return (
    <HashRouter>
      <ConfigProvider locale={zhCN}>
        <MyRouter />
      </ConfigProvider>
    </HashRouter>
  );
};
export default RouterConfig;
