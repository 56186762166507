import React from 'react'
import {LoadingOutlined} from "@ant-design/icons";
import './index.css';

const Loading = () => {
    return <div className={"loading-container"} >
        <div className={"loading-box"}>
            <span><LoadingOutlined /></span>
            <span>加载中...</span>
        </div>

    </div>
}

export default Loading
