import  request  from '@utils/request'

export async function getCaptcha(data) {
  return request('get_captcha', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    data,
    responseType: 'blob',
  })
}

export async function login(data) {
  return request('api/v1/token/app', {
    method: 'POST',
    data,
  })
}
