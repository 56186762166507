import { lazy, Suspense } from 'react'
const Dashboard = lazy(() => import('../pages/Dashboard'));
const OrderList = lazy(() => import('../pages/OrderList'));
const BillList = lazy(() => import('../pages/BillList'));
const UserList = lazy(() => import('../pages/User/UserList'));
const BannerList = lazy(() => import('@/pages/BannerList'));
const CategoryList = lazy(() => import('@/pages/CategoryList'));
const ThemeList = lazy(() => import('@/pages/ThemeList'));
const CouponList = lazy(() => import('@/pages/CouponList'));
const ImgList = lazy(() => import('@/pages/ImgList'));
const ArticleList = lazy(() => import('@/pages/ArticleList'));
const KnowledgeList = lazy(() => import('@/pages/KnowledgeList'));
const TagList = lazy(() => import('@/pages/TagList'));
const CollectList = lazy(() => import('@/pages/CollectList'));
const ProductList = lazy(() => import('@/pages/ProductList'));
const LogisticsList = lazy(() => import('@/pages/LogisticsList'));
const ShopList = lazy(() => import('@/pages/ShopList'));
const Setting = lazy(() => import('@/pages/Setting'));
import { Navigate } from 'react-router-dom'
import {
  TeamOutlined,
  FolderViewOutlined,
  PictureOutlined,
  OrderedListOutlined,
  PayCircleOutlined,
  ShoppingCartOutlined,
  ProductOutlined,
  LineChartOutlined,
  ShopOutlined,
  ReadOutlined,
  FileTextOutlined,
  ApartmentOutlined,
  TagOutlined,
  AccountBookOutlined,
  TruckOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons'
import Loading from "@/components/Loading";

let token = window.sessionStorage.getItem('TOKEN')
if (!token || token === '' || token === 'undefined') {
  token = null
}

const customRoutes = [
  {
    index: true,
    hide: true,
    element: <Navigate to='/login' />
  },
  {
    path: 'dashboard',
    name: '大盘信息',
    icon: <LineChartOutlined />,
    element: <Suspense fallback={<Loading />}> <Dashboard /></Suspense>
  },
  {
    path: 'banner',
    name: '海报管理',
    icon: <FolderViewOutlined />,
    element: <Suspense fallback={<Loading />}> <BannerList /></Suspense>
  },
  {
    path: 'theme',
    name: '专题管理',
    icon: <OrderedListOutlined />,
    element: <Suspense fallback={<Loading />}> <ThemeList /></Suspense>
  },
  {
    path: 'category',
    name: '栏目管理',
    icon: <ApartmentOutlined />,
    element: <Suspense fallback={<Loading />}> <CategoryList /></Suspense>
  },
  {
    path: 'img',
    name: '图片管理',
    icon: <PictureOutlined />,
    element: <Suspense fallback={<Loading />}> <ImgList /></Suspense>
  },
  {
    path: 'article',
    name: '文章管理',
    icon: <FileTextOutlined />,
    element: <Suspense fallback={<Loading />}><ArticleList /> </Suspense>
  },
  {
    path: 'tag',
    name: '标签管理',
    icon: <TagOutlined />,
    element: <Suspense fallback={<Loading />}><TagList /> </Suspense>
  },
  {
    path: 'product',
    name: '商品管理',
    icon: <ProductOutlined />,
    element: <Suspense fallback={<Loading />}><ProductList /> </Suspense>
  },
  {
    path: 'order',
    name: '订单管理',
    icon: <ShoppingCartOutlined />,
    element: <Suspense fallback={<Loading />}> <OrderList /></Suspense>
  },
  {
    path: 'coupon',
    name: '优惠管理',
    icon: <PayCircleOutlined />,
    element: <Suspense fallback={<Loading />}> <CouponList /></Suspense>
  },
  {
    path: 'bill',
    name: '账单管理',
    icon: <AccountBookOutlined />,
    element: <Suspense fallback={<Loading />}> <BillList /></Suspense>
  },
  {
    path: 'knowledge',
    name: '阅读付费',
    icon: <ReadOutlined />,
    element: <Suspense fallback={<Loading />}><KnowledgeList /> </Suspense>
  },
  {
    path: 'logistics',
    name: '物流管理',
    icon: <TruckOutlined />,
    element: <Suspense fallback={<Loading />}><LogisticsList /> </Suspense>
  },
  {
    path: 'user',
    name: '用户管理',
    icon: <TeamOutlined />,
    element: <Suspense fallback={<Loading />}> <UserList /></Suspense>
  },
  {
    path: 'collect',
    name: '用户收藏',
    icon: <StarOutlined />,
    element: <Suspense fallback={<Loading />}> <CollectList /></Suspense>
  },
  {
    path: 'shop',
    name: '店铺设置',
    icon: <ShopOutlined />,
    element: <Suspense fallback={<Loading />}> <ShopList /></Suspense>
  },
  {
    path: 'setting',
    name: '系统设置',
    icon: <SettingOutlined />,
    element: <Suspense fallback={<Loading />}> <Setting /></Suspense>
  },
]
export default customRoutes;
