import React from 'react';
import ReactDOM from 'react-dom';
import RouterConfig from './router'
import '@/assets/css/normalize.css'
import '@/assets/css/style.css'
import './index.css'


ReactDOM.render(
  <React.StrictMode>
    <RouterConfig />
  </React.StrictMode>,
  document.getElementById('root')
);