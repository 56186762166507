import  request  from '@utils/request'

export async function getThird(id, data = {}) {
  return request(`api/v1/third/${id}`, {
    method: 'GET',
    data,
  })
}

export async function editThird(id, data) {
  return request(`api/v1/third/${id}`, {
    method: 'PUT',
    data,
  })
}

export async function rename(id, data) {
  return request(`api/v1/third/${id}/rename`, {
    method: 'PUT',
    data,
  })
}

export async function change(id, data) {
  return request(`api/v1/third/${id}/change`, {
    method: 'PUT',
    data,
  })
}

export async function refresh() {
  return request(`api/v1/token/refresh`, {
    method: 'GET'
  })
}



